define('frontend/config/environment', [], function() {
  if (typeof FastBoot !== 'undefined') {
return FastBoot.config('frontend');
} else {

          var exports = {
            'default': {"modulePrefix":"frontend","environment":"production","rootURL":"/","locationType":"history","EmberENV":{"FEATURES":{},"EXTEND_PROTOTYPES":{"Date":false},"_APPLICATION_TEMPLATE_WRAPPER":false,"_DEFAULT_ASYNC_OBSERVERS":true,"_JQUERY_INTEGRATION":false,"_TEMPLATE_ONLY_GLIMMER_COMPONENTS":true},"APP":{"rootElement":"#ember-application","name":"frontend","version":"0.0.0+797fa2a4"},"apiHost":"https://api.sta.aws.waterfluence.com","yii2Host":"https://api.sta.aws.waterfluence.com","ember-bootstrap":{"formValidationErrorIcon":"fa fa-times","formValidationInfoIcon":"fa fa-info-circle","formValidationSuccessIcon":"fa fa-check","formValidationWarningIcon":"fa fa-warning"},"ember-concurrency-scroll":{"duration":1000},"ember-google-maps":{"key":"AIzaSyCGzxtgo4iJGF6Nc5OOxg7fTy6kX1suS4Q","libraries":["geometry","drawing","places"],"language":"en","version":"beta","src":"//maps.googleapis.com/maps/api/js?v=beta&libraries=geometry%2Cdrawing%2Cplaces&language=en&key=AIzaSyCGzxtgo4iJGF6Nc5OOxg7fTy6kX1suS4Q"},"fastboot":{"hostWhitelist":[{},{}]},"emberRollbarClient":{"enabled":true,"accessToken":"48c0188558344054b59defd58b445c7c","verbose":true,"captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"production","client":{"javascript":{"source_map_enabled":true,"code_version":"0.0.0+797fa2a","guess_uncaught_frames":true}}}},"ember-cli-mirage":{"usingProxy":false,"useDefaultPassthroughs":true},"exportApplicationGlobal":false}
          };
          Object.defineProperty(exports, '__esModule', {value: true});
          return exports;
        
}
});
